@import "../../index.scss";
.home__1 {
  margin-bottom: 2rem;
  background: $grey0;
  padding: 0 0.3rem;
  .home__box__1 {
    // background: $grey0;
    // background: #ece8e5;
    .home__rightBox {
      display: grid;
      grid-template-rows: 1fr 1fr 1fr;
      padding: 0 0.4rem;
    }
  }
  .home__box__2 {
    padding: 0 0.4rem;
    .home__leftBox {
      gap: 0.8rem;
      display: grid;
      // padding: 0 0.5rem;
    }
  }
  .home__box__3 {
    margin: 0.8rem 0;
    padding: 0 0.4rem;
    background: $red5;
    .home_3_title {
      display: flex;
      color: white;
      span {
        font-weight: 700;
        padding: 0.3rem 0 0 0.2rem;
        font-size: 1.4rem;
      }
    }
  }
  .home__box__4 {
    padding: 0 0.4rem;
    span {
      font-weight: 500;
      color: $green2;
      font-size: 1.4rem;
    }
    .home__box__4-layout {
      display: grid;
      gap: 0.8rem;
    }
  }
}
@media screen and (max-width:767px) {
  .home__1 {
    // margin-top: 3.3rem;
    margin-top: 6.3rem;
    .home > div:nth-child(2) {
      margin-top: auto;
    }
  }
}
@media screen and (min-width:768px) {
  .home__1 {
    // margin-top: -4.5rem;
    width: 100%;
    // border: 1px solid green;
    padding: 0 0.5rem;
    .homeTopNews {
      // border: 1px solid blue;
      // background: url("../../../public/img/test.jpg");
      height: 40rem;
    }
    .home__box__1 {
      width: 1200px;
      height: 500px;
      margin: 0 auto;
      position: relative;
      display: flex;
      flex-direction: row;
      // border: 1px solid red;
      // height: 1600px;
      .home__box {
        // padding: 0 1rem;
        // min-width: 600px;
        height: 100%;
        // background: white;
        width: 100%;
      }
      .home__rightBox {
        margin: 0 0.6rem;
        height: 100%;
        display: flex;
        flex-direction: column;
        .boxWrapper:last-child {
          border: none;
        }
      }
    }
    .home__box__2 {
      position: relative;
      display: flex;
      // align-items: start;
      margin: 1.8rem auto;
      padding: 0;
      width: 1200px;
      height: 650px;
      // padding: 2rem 0;
      // border: 1px solid $grey3;
      .home__leftBox {
        gap: 0;
        margin: 0;
        float: left;
        display: grid;
        //????????????????????????????
        grid-template-rows: 3 (1fr);
        width: calc(100% - 320px);
      }
      .home__rightBox {
        display: flex;
        justify-content: right;
        align-items: center;
        height: 100%;
        width: 320px;
      }
    }
    .topNews {
      // margin: 1.2rem 0;
      // border: 1px solid red;
      min-height: 410px;
    }
    .home__box__3 {
      width: 100%;
      background: radial-gradient(circle at 100%, $dark3, $dark3 40%, $dark4 80%, $dark2 80%);
      display: flex;
      flex-direction: column;
      padding: 0.3rem 1rem 1rem 1rem;
      border-radius: 5px;
      .home_3_title {
        span {
          font-size: 2rem;
        }
      }
      .box3 {
        .box3__left {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          gap: 1rem;
        }
      }
    }
    .home__box__4 {
      padding: 0;
      span {
        font-size: 1.8rem;
      }
      .home__box__4-layout {
        margin-bottom: 1rem;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
      }
    }
  }
}

@media screen and (min-width:1024px) {
  .home__1 {
    padding: 0;
    // width: 1200px;
    .home__box__1 {
      flex-direction: row;
      // height: 26rem;
    }
  }
}
