@import "../../index.scss";

.singlePost {
  position: relative;
  display: flex;
  flex-direction: column;
  // padding-top: 7rem;
  // padding-bottom: 2rem;
  #t1234 {
    top: 0;
    // opacity: 0.1;
    // filter: grayscale(100%);
    // -webkit-filter: grayscale(100%);
    // position: sticky;
    // z-index: 1;
    width: 100%;
    height: 60vh;
    margin: 0 auto;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .content {
    z-index: 1;
    background: white;
    margin: 0 auto;
    // margin-top: -50vh;
    // width: 1400px;
    padding: 1.5rem 0.8rem 0.4rem 0.8rem;
    .mainContent {
      display: flex;
      flex-direction: column;
      .mainContent-wrapper {
        display: flex;
        flex-direction: column;
        .mainContent-cat {
          display: block;
          background: linear-gradient($grey3, $grey5);
          font-size: 1.2rem;
          color: white;
          padding: 0.35rem 1rem;
          border-radius: 0.3rem;
          width: fit-content;
        }
        .mainContent-title {
          color: $barGreen5;
          margin: 2rem 0 1rem 0;
          font-size: 1.8rem;
          line-height: 2.1rem;
        }
        .mainContent-lead {
          line-height: 1.4rem;
          margin-bottom: 0.8rem;
          font-size: 1.2rem;
          font-weight: 700;
        }
        .mainContent-img {
          display: none;
          position: relative;
          width: 100%;
          aspect-ratio: 3/2;
          // border: 1px solid red;
          img {
            display: block;
            // display: none;
            aspect-ratio: 3/2;
            // aspect-ratio: 16/9;
            width: 100%;
            height: auto;
            object-fit: cover;
            border-radius: 0.3rem;
          }
          .mainContent-img_source {
            position: absolute;
            bottom: 0.5rem;
            left: 0.5rem;
            background: rgba(30, 30, 30, 0.8);
            padding: 0.1rem 0.5rem;
            border-radius: 0.2rem;
            span {
              font-size: 0.9rem;
              color: rgba(255, 255, 255, 0.85);
              a {
                color: rgba(255, 255, 255, 0.85);
                font-weight: normal;
              }
            }
          }
          #levo,
          #desno {
            display: none;
          }
        }
        .mainContent-social {
          width: 100%;
          min-height: 60px;
          height: 60px;
        }
        .mainContent-bodyText {
          width: 100%;
          min-height: 150px;
        }
        .mainContent-subtitle,
        .mainContent-subtitle3 {
          padding: 0.8rem 0 0.2rem 0;
          color: black;
          line-height: 1.6rem;
        }
        .mainContent-subtitle {
          margin-top: 0.5rem;
          color: $barGreen5;
          font-size: 1.4rem;
        }
        .mainContent-subtitle3 {
          color: $barGreen3;
          font-size: 1.2rem;
          font-weight: 600;
        }
        .tags {
          margin: 1.5rem 0 1rem 0;
          .tags-title {
            font-size: 1.6rem;
            font-weight: 700;
            border: none;
          }
          .tags__arr {
            margin-top: 0.58rem;
            display: flex;
            flex-wrap: wrap;
            a {
              line-height: 1.4rem;
              font-size: 1rem;
              text-align: center;
              text-transform: uppercase;
              padding: 0.2rem 0.8rem;
              margin-right: 0.4rem;
              border-radius: 0.3rem;
              color: black;
              border: 1px solid black;
              background: linear-gradient(#ffffff, #fdfdfd);
              margin-bottom: 0.4rem;
            }
          }
        }
        .time {
          display: flex;
          // border: 1px solid red;
          font-size: 1rem;
          color: $grey5;
          font-family: "Times New Roman", Times, serif;
          padding-bottom: 0.2rem;
          div {
            a {
              color: $green4;
            }
          }
        }
      }
    }
  }
  .home__box__11 {
    display: none;
  }
  .home__box__13 {
    display: none;
  }
}

.PR {
  display: flex;
  flex-direction: column;
  background: rgb(32, 30, 30);
  background: rgb(32, 77, 81);
  #t1234 {
    opacity: 0.1;
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100vh;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .content {
    position: relative;
    width: 100%;
    .mainContent {
      z-index: 2;
      .mainContent-wrapper {
        .mainContent-cat {
          background: $gold1;
          font-size: 1.2rem;
          color: white;
          padding: 0.1rem 1rem;
          border-radius: 5px;
        }
        .mainContent-title {
          color: $barGreen5;
          color: black;
          color: white;
          margin: 2rem 0 1rem 0;
          font-size: 1.8rem;
          line-height: 2rem;
        }
        .mainContent-img {
          position: relative;
          height: auto;
          width: 100%;
          aspect-ratio: 16/9;
          margin-bottom: 0.3rem;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          .mainContent-img_source {
            color: rgba(255, 255, 255, 0.85);
            position: absolute;
            font-size: 0.9rem;
            bottom: -1.6rem;
            right: 9rem;
            padding: 0.1rem 0.5rem;
            border-radius: 0.3rem;
            span {
              font-size: 0.85rem;
              a {
                color: rgba(255, 255, 255, 0.85);
                font-weight: normal;
              }
            }
          }
        }

        .mainContent-lead {
          color: rgba(255, 255, 255, 0.761);
          line-height: 1.4rem;
          margin: 0;
          padding: 0;
          margin-bottom: 0.8rem;
          padding-top: 3rem;
          strong {
            font-size: 1.4rem;
          }
        }
        .mainContent-subtitle,
        .mainContent-subtitle3 {
          padding: 0.8rem 0 0.2rem 0;
          color: black;
          color: white;
        }
        .mainContent-subtitle {
          margin-top: 0.5rem;
          color: red;
          font-size: 1.4rem;
          line-height: 2rem;
        }
        .mainContent-subtitle3 {
          color: rgb(228, 228, 228);

          font-size: 1.2rem;
          line-height: 1.6rem;
          font-weight: 600;
        }
        .tags {
          text-align: center;
          margin: 1.5rem 0;
          .tags-title {
            margin-bottom: 0.3rem;
            font-size: 1.6rem;
            font-weight: 400;
            border: none;
          }
          .tags__arr {
            justify-content: center;
            display: flex;
            flex-wrap: wrap;
            a {
              text-align: center;
              min-width: 50px;
              font-size: 1.2rem;
              padding: 0.15rem 0.8rem;
              margin-right: 0.4rem;
              color: white;
              background: $barGreen3;
              background: grey;
              background: transparent;
              border-bottom: 2px solid gray;
              margin-bottom: 0.3rem;
              font-style: normal;
              font-style: italic;
              opacity: 0.8;
              letter-spacing: 0.02rem;
            }
          }
        }
        .time {
          margin-right: 0rem;
          text-align: right;
          margin-top: 0.3rem;
          font-size: 0.8rem;
          color: $grey3;
          font-weight: 400;
          text-transform: uppercase;
          font-family: "Times New Roman", Times, serif;
          padding-bottom: 0.2rem;
        }
      }
    }
  }
  .newsAside {
    width: 100%;
    padding: 0 0.4rem;
    .newsAside-box {
      .newsAside-box-title {
        font-size: 1.4rem;
        border-bottom: 3px solid $green4;
      }
    }
  }
  .home__box__11 {
    max-width: 600px; //????
    display: none;
    .newsAside-box {
      .newsAside-box-title {
        margin-top: 1rem;
        font-size: 1.2rem;
        border-bottom: 3px solid $green4;
        margin-bottom: 0.5rem;
      }
    }
  }
  .home__box__13 {
    display: none;
    .newsAside-box {
      .newsAside-box-title {
        margin-top: 1rem;
        font-size: 1.2rem;
        border-bottom: 3px solid $green4;
        margin-bottom: 0.5rem;
      }
    }
  }
}
@media screen and (min-width:768px) {
  .singlePost {
    #t1234 {
    }
    .content {
      width: 1200px;
    }
  }
  .PR {
    .content {
      padding: 0;
      margin: 0;
      padding-top: 6rem;
      margin-bottom: 2rem;
      display: flex;
      flex-direction: row;
      gap: 1.5rem;
      .mainContent {
        padding: 0;
        margin: 0 auto;
        width: calc(100% - 480px); //??????
        grid-row-end: auto;
        .mainContent-socialButtons {
          padding: 0 1rem;
          margin: 0;
        }
        .mainContent-wrapper {
          padding: 0 1rem;
          .mainContent-title {
            padding: 3rem 0 2rem 0;
            font-size: 5.2rem;
            line-height: 5.8rem;
            font-weight: 400;
            font-style: italic;
            text-align: center;
            color: rgb(220, 223, 216);
          }
          .mainContent-subtitle {
            font-size: 1.6rem;
          }
          .mainContent-subtitle3 {
            font-size: 1.45rem;
            line-height: 1.8rem;
          }
          .mainContent-lead {
            margin: 1rem 0 0 -10rem;
            width: calc(50% + 10rem);
            padding-bottom: 1.5rem;
            strong {
              font-size: 2rem;
              line-height: 2.3rem;
              font-weight: 400;
              font-style: italic;
              opacity: 0.9;
            }
          }
          .time {
            justify-content: flex-end;
            // border: 1px solid red;
            display: flex;
            width: 100%;
            text-align: right;
            margin-top: 0.4rem;
            font-size: 0.9rem;
            color: rgb(186, 186, 186);
          }
          .mainContent-img {
            margin-left: -10rem;
            width: calc(100% + 20rem);
            height: 650px;
            #levo,
            #desno {
              height: auto;
              position: relative;
              width: 120px;
              transform: translateY(-250px);
              opacity: 0.9;
            }
            #levo {
              margin-right: 30px;
            }
            #desno {
              margin-left: 30px;
            }
            img {
              margin: 0 auto;
              width: calc(100% - 300px); //dodati za srednju mg className
              height: 650px;
            }
          }
          .tags {
            .tags__arr {
              a {
                font-size: 1.2rem;
                margin-right: 0.8rem;
              }
            }
          }
        }
      }
    }
    .newsAside {
      width: 380px;
      padding: 0;
      margin: 0;
      .newsAside-box {
        margin: 1rem 0;
      }
    }
    .newsAside.foot {
      width: 610px;
      .home__box__11 {
        display: inline-block;
        position: relative;
        align-items: start;
        margin-top: 1.5rem;
        h2 {
          padding-right: 1rem;
          display: inline;
          margin-top: 1rem;
          font-size: 1.6rem;
          border-bottom: 4px solid $barGreen5;
        }
        .home__rightBox {
          display: grid;
          grid-template-rows: 1fr 1fr 1fr;
        }
      }
      .home__box__13 {
        display: inline;
        margin-top: 2rem;
        h2 {
          padding-right: 1rem;
          display: inline;
          border-bottom: 4px solid $barGreen5;
          line-height: 2.2rem;
          font-size: 1.6rem;
        }
        .home__rightBox {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 0.8rem;
          padding-top: 1.2rem;
        }
      }
    }
  }
}
