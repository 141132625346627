@import "../../index.scss";

.bodyText {
  // border: 1px solid red;
  padding-top: 3rem;
  div {
    position: relative;
    // padding: 0 3rem;
  }
  p {
    // color: red;
    font-size: 1.15rem;
    line-height: 1.75rem;
    position: relative;
    blockquote {
      margin: 1.3rem 0;
      padding: 1rem 1.2rem 1.5rem 1.2rem;
      background: #f4f2f0;
      font-size: 1.2rem;
      h3 {
        padding: 0.5rem 0;
        color: $barGreen3;
        font-size: 1.4rem;
        line-height: 2rem;
      }
    }
    blockquote::before {
      position: absolute;
      content: "";
      left: 0.5rem;
      right: 0.5rem;
      top: 0.5rem;
      bottom: 0.5rem;
      border: 1px solid white;
      a {
        cursor: pointer;
      }
    }
    span {
      font-size: 1rem;
      font-weight: 900;
    }
    a {
      font-weight: 900;
      font-size: 1.2rem;
      color: rgb(227, 211, 33);
      text-decoration: underline;
    }
    ul,
    ol {
      padding: 0.2rem 0 0.2rem 1.6em;
      li {
        padding-bottom: 0.12rem;
      }
    }
    b {
      font-size: 1.15rem;
      color: $barGreen6;
    }
    i {
      font-size: 1.1rem;
    }
    #test1234 {
      // top: 0;
      margin: 0;
      position: absolute;
      opacity: 0.05;
      border: none;
      width: 60%;
      margin-left: 20%;
      filter: grayscale(100%);
      -webkit-filter: grayscale(100%);
    }
    img {
      // margin-top: 2rem;
      // position: absolute;
      margin: 2rem 0;
      margin-top: 5rem;
      border: 1px solid rgb(65, 65, 65);
      // border: 1px solid rgba(255, 255, 0, 0.37);
      // border: 1px solid white;
      width: 100%;
      // cursor: pointer;
      border-radius: 0.5rem;
      // filter: grayscale(100%);
      // -webkit-filter: grayscale(100%);
      // filter: brightness(0) invert(1);
    }
  }
  blockquote {
    margin: 1.3rem 0.2rem;
    padding: 1rem 1.2rem 1.5rem 1.2rem;
    background: #f1eae3;
    font-size: 1.2rem;
    h3 {
      padding: 0.5rem 0;
      color: $barGreen3;
      font-size: 1.25rem;
      line-height: 1.75rem;
    }
  }
  blockquote::before {
    position: absolute;
    content: "";
    left: 0.7rem;
    right: 0.7rem;
    top: 0.5rem;
    bottom: 0.5rem;
    border: 1px solid white;
    a {
      cursor: pointer;
    }
  }
}
.bodyText.PR {
  color: rgba(219, 219, 219, 0.831);
  div {
    padding: 0 0.5rem;
  }
}
@media screen and (min-width:768px) {
  .bodyText {
    p {
      font-size: 1.25rem;
      line-height: 1.85rem;
      span {
        font-size: 1.12rem;
      }
      a {
        text-decoration: none;
        font-size: 1.25rem;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
      ul,
      ol {
        li {
          font-size: 18px;
        }
      }
      b {
        font-size: 1.15rem;
      }
      i {
        font-size: 1.1rem;
      }
    }
    blockquote {
      background: #f4f2f0;
    }
  }
  .bodyText.PR {
    div {
      padding: 0 3rem;
    }
  }
}
