@import "../../../index.scss";

.side-drawer {
  position: fixed;
  position: absolute;
  top: 50px;
  left: 0;
  bottom: 0;
  min-height: 100vh;
  width: 100%;
  background: white;
  // background: $barGreen4;
  transform: translateX(-100%);
  transition: transform 0.5s ease-out;
  z-index: 999;
  overflow-y: scroll;
  ul {
    padding: 0.5rem;
    list-style: none;
    display: flex;
    flex-direction: column;
    text-align: center;
    text-align: left;
    vertical-align: middle;
    a {
      padding: 0.1rem 0;
      color: black;
      font-size: 1.2rem;
      vertical-align: middle;
      img {
        vertical-align: middle;
        width: 25px;
        height: 25px;
        margin-right: 5px;
      }
    }
    .subUl {
      margin-left: 1rem;
    }
  }

  li {
    .top-link2 {
      // line-height: 1.6rem;
      display: inline-block;
      width: 100%;
      border-bottom: 1px solid rgba(35, 35, 35, 0.353);
      font-size: 1.4rem;
      font-weight: 500;
      text-decoration: none;
      color: black;
      font-weight: bold;
      &.active {
        color: $green3;
        font-weight: bold;
      }
      img {
        width: 25px;
        height: 25px;
        margin: 0 5px 0 5px;
      }
    }
  }
}

.side-drawer.open {
  transform: translateX(0);
}

@media screen and (min-width:768px) {
  .side-drawer {
    display: none;
  }
}
