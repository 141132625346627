@import "../../index.scss";

.bars {
  background: $green5;
  position: absolute;
  display: flex;
  min-width: 300px;
  width: 100%;
  // border: 1px solid red;
  height: 50px;
  min-height: 35px;
  z-index: +999;
  .bars__logo {
    padding: 0.15rem 0.2rem;
    display: flex;
    img {
      width: auto;
    }
  }
}
@media screen and (max-width:767px) {
  .bars {
    transition: all 0.25s ease-out;
  }
  .bars.small {
    top: -50px;
    transition: all 0.15s ease-out;
  }
}
@media screen and (min-width:768px) {
  .bars {
    background: transparent;
    width: 100%;
    height: 4.5rem;
    .bars__logo {
      align-items: center;
      height: 100%;
      margin: 0;
      padding-left: 0.3rem;
      img {
        width: auto;
        height: 3.5rem;
      }
    }
    .bars__links {
      width: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
    }
  }
}
//promenjeno sa 768px
@media screen and (min-width:1024px) {
  .bars {
    .bars__logo {
      padding: 0;
      align-items: center;
      height: 100%;
      img {
        width: auto;
        height: 4rem;
      }
    }
    .bars__links {
      margin: 0 auto;
      display: flex;
      flex-direction: column;
    }
  }
  .bars.small {
    height: 3.5rem;
    .bars__logo {
      left: calc(50% - 645px);
      img {
        height: 3.4rem;
      }
    }
  }
}
