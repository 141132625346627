@import "../../index.scss";

.pagination {
  margin: 0.8rem 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  div {
    padding: 0 3rem;
    text-align: center;
    border-radius: 5px;
    background: white;
    border: 1px solid $green4;
    height: 3.5rem;
    line-height: 3.5rem;
    color: $green4;
    font-size: 1.6rem;
    font-weight: 600;
    cursor: pointer;
    &:hover {
      background: $green4;
      color: white;
    }
  }
  .open {
    display: none;
  }
}
@media screen and (min-width:768px) {
  .pagination {
    padding: 1.5rem 0 1.5rem 0;
  }
}
