@import "./index.scss";

.app {
  position: relative;
  .app-wrapper {
    display: flex;
    .app-body {
      position: relative;
      margin: 0 auto;
    }
  }
}

@media screen and (min-width: 768px) {
  .app {
    .app-wrapper {
      .app-body {
        // margin-top: 4.5rem;
      }
    }
  }
}
@media screen and (min-width: 1024px) {
  .app {
    // border: 1px solid red;
    .app-wrapper {
      // border: 1px solid blue;
      .app-body {
        width: 100%;
        // border: 1px solid black;
        // width: 1024px;
      }
    }
  }
}
// @media screen and (min-width:1256px) {
//   .app {
//     .app-wrapper {
//       .app-body {
//         width: 1200px;
//       }
//     }
//   }
// }
