@import "../../../index.scss";

.topBar {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  padding-right: 0.3rem;
  .topBar__link {
    display: none;
    font-size: 0.8rem;
    line-height: 0.8rem;
    // text-align: left;
    text-decoration: none;
    color: white;
    color: black;
    text-decoration: none;
    text-shadow: 0px 1px 3px grey;
    i {
      color: white;
      color: black;
      font-size: 1em;
      margin-left: 0.2rem;
    }
  }
}
@media screen and (min-width:768px) {
  .topBar {
    width: 100%;
    // background: #d25103;
    // background: rgb(37, 111, 65);
    transition: all 0.25s ease-out;
    flex-direction: row;
    height: auto;
    justify-content: space-between;
    // padding: 1rem 0;
    // margin: 1rem 0;
    // border: 1px solid yellow;
    // text-align: center;
    // align-items: center;

    .topBar__link {
      display: inline;
      font-size: 1rem;
      line-height: 1.5rem;
      padding: 0.5rem 0 0 0;
      text-shadow: none;
      i {
        margin-left: 0.8rem;
        font-size: 1.3rem;
      }
    }
  }
  .topBar.close {
    transition: all 0.15s ease-out;
    display: none;
  }
}
@media screen and (min-width:1024px) {
  .topBar {
    padding: 0;
    // background: $barGreen4;
  }
}
