@import "../../index.scss";
.news2 {
  width: 100%;
  h1 {
    display: inline-block;
    border-bottom: 4px solid $green4;
    line-height: 1.8rem;
    font-size: 1.4rem;
    margin: 0.6rem 0 0.6rem 0.3rem;
  }
  .home__box__1-1 {
    .home__box {
      width: 100%;
    }
    .home__rightBox {
      display: flex;
      flex-direction: column;
      color: white;
      width: 100%;
      padding: 0 0.4rem;
      // border: 1px solid red;
    }
  }
  .home__box__11 {
    padding: 0 0.4rem;
  }
  .home__box__2 {
    padding: 0 0.4rem;
    .home__box__2-layout {
      display: flex;
      flex-direction: column;
    }
  }
}
@media screen and (max-width:767px) {
  .news2 {
    // margin-top: 52px;
    margin-top: 6.3rem;
    .home__box__1 {
      .home__rightBox {
        h3 {
          display: none;
        }
      }
    }
    .home > div:nth-child(2) {
      margin-top: auto;
    }
  }
}
@media screen and (min-width:768px) {
  .news2 {
    padding-bottom: 3rem;
    h1 {
      font-size: 2rem;
      line-height: 2.5rem;
      color: $green4;
      margin-bottom: 0.8rem;
    }
    .home__box__1-1 {
      position: relative;
      align-items: start;
      display: flex;
      flex-direction: column;
      .home__box {
        width: 100%;
        height: 100%;
        margin: 0;
      }
    }
    .home__box__11 {
      padding: 0;
      position: relative;
      align-items: start;
      height: 640px;
      display: flex;
      flex-direction: row;
      // border: 1px solid red;
      margin: 0.8rem 0;
      .home__rightBox {
        width: 100%;
        height: 100%;
        margin-right: 2rem;
        display: grid;
        grid-template-rows: 1fr 1fr 1fr;
        padding: 10px 0;
      }
      .home__adBox {
        display: flex;
        width: 320px;
        height: 100%;
        // justify-content: right;
        align-items: center;
        // border: 1px solid red;
        // justify-content: start;
      }
    }
    .home__box__2 {
      padding: 0;
      margin: 1rem 0;
      .home__box__2-layout {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1rem;
        justify-content: space-between;
      }
      .no-add {
        display: none;
      }
    }
  }
}
