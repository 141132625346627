@import "../../index.scss";

.footer {
  background: $dark5;
  .home__section5-top {
    padding: 0.4rem;
    display: flex;
    flex-direction: column;
    span {
      text-align: left;
      padding: 0.2rem 0;
      color: white;
    }
  }
  .home__section5-middle {
    margin: 0 auto;
    border: 1px 0 1px 0 solid grey;
    border-top: 0.5px solid grey;
    border-bottom: 0.5px solid grey;
    color: white;
    display: flex;
    justify-content: space-between;
  }
  .home__section5-middle > div {
    padding: 0.3rem 0.4rem;
    display: flex;
    flex: 1;
    align-items: center;
    a {
      color: white;
      // padding-left: 0.8rem;
      i {
        cursor: pointer;
        font-size: 1.2rem;
        color: $dark0;
        margin-left: 0.5rem;
      }
      img {
        width: 35px;
      }
    }
  }
  .home__section5-middle > div:last-child {
    justify-content: flex-end;
  }
  .home__section5-bottom {
    text-align: center;
    margin: 0 auto;
    color: white;
    padding: 2rem 0;
  }
}
@media screen and (min-width:768px) {
  .footer {
    .home__section5-top {
      padding: 2.5rem 15%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      a {
        text-align: center;
        span {
          text-align: center;
        }
      }
    }
    .home__section5-middle {
      width: 80%;
    }
    .home__section5-middle > div {
      // border: 1px solid white;
      padding: 0.5rem 0.2rem;
      a {
        color: white;
        i {
          font-size: 1.4rem;
          margin-left: 1rem;
        }
      }
    }
  }
}
