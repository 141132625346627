$small: 300px;
$medium: 768px;
$large: 1024px;
$extraLarge: 1368px;

html {
  scroll-behavior: smooth;
  // height: 100%;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

* {
  margin: 0 0;
  padding: 0 0;
  font-size: 16px;
  box-sizing: border-box;
  font-family: "Sarala", sans-serif;
  transition: 2s all east-out;
  text-decoration: none;
  font-display: swap;
}

$green1: #34c118;
$green2: #02a312;
$green3: #00710b;
$green3: #016d0c;
$green4: #09491f;
$green5: #0c3d1d;
$green5: #0e3e1f;

$barGreen1: #67cbb4;
$barGreen2: #39a78e;
$barGreen3: #215f51;
$barGreen4: #1a4c40;
$barGreen5: #153d33;
$barGreen6: #0a1e19;
$barGreen7: #0e1614;

$orange0: #d25103;
$orange1: #a06519;
$orange2: #965315;
$orange3: #7c4512;
$orange4: #693d0b;
$orange5: #5e250e;

$dark0: #fe662e;
$dark1: #dc391c;
$dark2: #a33c13;
$dark3: #832f0e;
$dark4: #682910;
$dark5: #4d0800;
$dark6: #64180c;

$red0: #fd0000;
$red1: #db3c27;
$red2: #a12110;
$red3: #922819;
$red4: #981111;
$red5: #731d11;

$silver1: #2c7983;
$silver2: #235d64;
$silver3: #1c4d53;
$silver4: #1d4d55;
$silver5: #153a3f;
$silver6: #3c5758;

$grey0: #f9f9f9;
$grey1: #ececec;
$grey2: #b1b1b1;
$grey2: #9b9b9b;
$grey3: #656565;
$grey4: #424242;
$grey5: #2c2c2c;

$gold1: rgb(162, 122, 0);

@media only screen and (max-width: 768px) {
  * {
    /*CSS animations*/
    -webkit-animation: none !important;
    -moz-animation: none !important;
    -o-animation: none !important;
    -ms-animation: none !important;
    animation: none !important;
  }
}
