@import "../../../index.scss";

.navBar {
  position: absolute;
  // position: fixed;
  display: flex;
  width: 100%;
  transition: all 0.5s ease-out;
  .links {
    display: flex;
    white-space: nowrap;
    .nav-link-wrapper {
      .nav-link {
        letter-spacing: 0.06rem;
        font-size: 1.2rem;
        padding: 0 0.5rem;
      }
      .botDiv {
        display: none;
      }
    }
  }
  .links::-webkit-scrollbar {
    display: none;
  }
}
@media screen and (max-width:767px) {
  .navBar {
    height: 51px;
    background: rgb(249, 249, 249);
    left: 0;
    right: 0;
    overflow: auto;

    z-index: +2;

    // overflow: hidden;
    // width: 100%;
    .links {
      height: 100%;
      -ms-overflow-style: none;
      overflow: -moz-scrollbars-none;
      overflow-x: scroll;
      white-space: nowrap;
      .nav-link-wrapper {
        height: 100%;
        .nav-link {
          height: 50px;
          line-height: 50px;
          color: $barGreen5;
          font-weight: 600;
        }
      }
    }
  }
}
@media screen and (min-width:768px) {
  .navBar {
    // border: 1px solid red;
    // width: 1024px;
    flex: 3;
    justify-content: space-around;
    position: relative;
    // height: 100%;
    // height: 40px; //???????????????????
    background: transparent;
    .links {
      // border: 1px solid blue;
      width: 1024px;
      // width: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      height: 100%;
      .nav-link-wrapper {
        position: relative;
        height: 100%;
        display: flex;
        flex-direction: column;
        .nav-link {
          align-items: center;
          text-decoration: none;
          color: white;
          font-weight: 500;
          letter-spacing: 0.02rem;
          z-index: +100;
          z-index: +999;
          height: 100%;
          display: flex;
          padding: 0 2rem;
          &.active {
            border: none;
            font-weight: bold;
          }
        }
        .nav-link::before {
          position: absolute;
          content: "";
          top: 0;
          bottom: 0;
          left: 0.5rem;
          right: 0.5rem;
          // clip-path: polygon(2% 0, 100% 0%, 98% 100%, 0% 100%);
        }
        .botDiv {
          display: inline;
          height: 0;
          position: relative;
          .drop_down_menu {
            z-index: +100;
            width: 500px;
            position: absolute;
            display: grid;
            grid-template-columns: 1fr 1fr;
            top: 0;
            left: 0;
            background: white;
            box-shadow: 1px 2px 3px rgb(0, 0, 0);
            padding: 0.3rem 0.4rem;
            border-bottom-left-radius: 0.3rem;
            border-bottom-right-radius: 0.3rem;
            list-style-type: none;
            a {
              border-radius: 5px;
              color: black;
              line-height: 35px;
              align-items: center;
              vertical-align: middle;
              font-size: 1.1rem;
              flex: 0 0 50%;
              margin: 3px 0;
              img {
                position: relative;
                width: 30px;
                height: 30px;
                margin: 0 0.3rem;
                top: 50%;
                transform: translateY(-50%);
              }
              &:hover {
                background: rgb(220, 220, 220);
              }
            }
          }
          .drop_down_menu.small {
            width: 250px;
            grid-template-columns: 1fr;
          }
          .drop_down_menu.close {
            display: none;
          }
        }
      }
    }
    .links.last {
      position: relative;
      width: 30px;
      height: 100%;
      cursor: pointer;
      .nav-link-wrapper {
        width: 100%;
        height: 100%;
        img {
          width: 20px;
          height: 100%;
        }
        .botDiv {
          display: inline;
          .drop_down_menu {
            z-index: +100;
            width: 250px;
            position: absolute;
            display: grid;
            grid-template-columns: 1fr;
            top: 18px;
            left: 0;
            background: white;
            box-shadow: 1px 2px 3px rgb(0, 0, 0);
            padding: 0.3rem 0.4rem;
            border-bottom-left-radius: 0.3rem;
            border-bottom-right-radius: 0.3rem;
            list-style-type: none;
            a {
              border-radius: 5px;
              color: black;
              line-height: 35px;
              align-items: center;
              vertical-align: middle;
              font-size: 1.1rem;
              flex: 0 0 50%;
              margin: 3px 0;
              z-index: +999;
              img {
                position: relative;
                width: 30px;
                height: 30px;
                margin: 0 0.3rem;
                top: 50%;
                transform: translateY(-50%);
              }
              &:hover {
                background: rgb(220, 220, 220);
              }
            }
          }
          .drop_down_menu.close {
            display: none;
          }
        }
      }
    }
  }
}
