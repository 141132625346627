@import "../../index.scss";

.mainNews {
  display: flex;
  height: 400px;
  .artBox-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    .artBox-img {
      display: block;
      height: 100%;
      img {
        height: 280px;
        width: 100%;
        object-fit: cover;
        overflow: hidden;
      }
    }
    .artBox-desc {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 0 0.4rem;
      background: linear-gradient(to bottom, $silver5 0%, $silver5 15%);
      h3 {
        padding-top: 0.8rem;
        line-height: 1.7rem;
        font-size: 1.4rem;
        color: white;
        font-weight: normal;
      }
      .artBox-desc-other {
        a {
          font-size: 1.1rem;
          font-weight: 600;
          color: $green2;
          margin-right: 0.2rem;
        }
        span {
          display: none;
          font-size: 0.85rem;
          text-transform: lowercase;
          color: $grey2;
        }
      }
      .artBox-lead {
        display: none;
      }
      button {
        display: none;
      }
    }
  }
}
.mainNews.category {
  height: 280px;
  .artBox-wrapper {
    .artBox-img {
      img {
        height: 100%;
      }
    }
    .artBox-desc {
      width: 100%;
      background: linear-gradient(to bottom, rgba(143, 143, 143, 0) 0%, rgba(24, 24, 24, 0.5) 20%, rgba(24, 24, 24, 0.75) 90%);
    }
  }
}
//all news css
.boxWrapper {
  .artBox-wrapper {
    padding: 0.4rem 0;
    height: 100%;
    display: flex;
    .artBox-img {
      img {
        width: 120px;
        height: 100%;
        object-fit: cover;
        overflow: hidden;
        border-radius: 4px;
      }
    }
    .artBox-desc {
      padding-left: 0.4rem;
      display: flex;
      flex-direction: column;
      width: calc(100% - 120px);
      h3 {
        color: black;
        font-weight: 500;
        font-size: 1.1rem;
        line-height: 1.3rem;
      }
      .artBox-desc-other {
        margin-top: auto;
        padding-top: 0.4rem;
        line-height: 0.9rem;
        a {
          font-size: 1rem;
          font-weight: 600;
          color: $green2;
          color: $green3;
          margin-right: 0.2rem;
        }
        span {
          display: none;
          font-size: 0.75rem;
          color: $grey2;
          font-weight: 600;
          text-transform: lowercase;
        }
      }
      .artBox-lead {
        display: none;
      }
      button {
        display: none;
      }
    }
  }
}
// .boxWrapper:last-child {
//   border: none;
// }

// .boxWrapper.box1 {
//   height: 120px;
//   border-top: 1px solid $silver1;
//   .artBox-wrapper {
//     .artBox-img {
//       img {
//         border-radius: 5px;
//       }
//     }
//   }
// }
// .boxWrapper.box2 {
//   display: flex;
//   height: 120px;
//   border-bottom: 1px solid $grey4;
//   .artBox-wrapper {
//     .artBox-img {
//       img {
//         border-radius: 5px;
//       }
//     }
//     .artBox-desc {
//       span {
//         color: rgb(168, 168, 168);
//         display: inline;
//         display: none;
//       }
//     }
//   }
// }

// top news
.boxWrapper.topNews {
  background: #ece8e5;
  margin-bottom: 1rem;
  .artBox-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    .artBox-img {
      padding: 0.3rem 0.6rem;
      flex: 1;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        overflow: hidden;
        border-radius: 0;
      }
    }
    .artBox-desc {
      width: calc(100% - 1.2rem);
      display: flex;
      flex-direction: column;
      .artBox-desc-cat {
        display: none;
      }
      a {
        order: 2;
        h3 {
          display: inline-block;
          color: black;
          font-weight: bold;
          font-size: 1.5rem;
          line-height: 2rem;
          text-align: center;
        }
      }
      .artBox-desc-other {
        order: 1;
        text-align: center;
        padding: inherit;
        margin-top: 1.5rem;
        padding-bottom: 1rem;
        a {
          color: black;
          font-size: 1rem;
          text-transform: uppercase;
        }
      }
      .artBox-lead {
        display: inline;
        order: 3;
        color: $grey5;
        text-align: center;
        padding: 0.5rem 0;
      }
      button {
        display: inline;
        font-size: 1.1rem;
        order: 4;
        padding: 0.2rem;
        width: 180px;
        border-radius: 0;
        background: $green5;
        color: white;
        text-align: center;
        margin: 1rem auto;
        z-index: 0;
      }
    }
  }
}

// .boxWrapper.box2:last-child {
//   border-bottom: none;
// }
// .boxWrapper.box3 {
//   height: 120px;
//   display: flex;
//   flex-direction: row;
//   border-bottom: 1px solid white;
//   .artBox-wrapper {
//     .artBox-img {
//       img {
//         border-radius: 5px;
//       }
//     }
//     .artBox-desc {
//       h3 {
//         color: white;
//       }
//       .artBox-desc-other {
//         color: $green4;
//         span {
//           display: none;
//         }
//       }
//     }
//   }
// }
// .boxWrapper.box4 {
//   display: flex;
//   height: 120px;
//   border-bottom: 1px solid $grey4;
//   .artBox-wrapper {
//     .artBox-img {
//       img {
//         border-radius: 8px;
//       }
//     }
//   }
//   .artBox-desc {
//     a {
//       h3 {
//         color: black;
//         font-size: 1rem;
//       }
//     }
//   }
// }
// .boxWrapper.box4:last-child {
//   border: none;
// }
.boxWrapper.sideBox {
  display: flex;
  padding: 0;
  height: 120px;
  box-shadow: none;
  color: black;
  .artBox-wrapper {
    .artBox-desc {
      h3 {
        color: black;
        line-height: 1.3rem;
        font-size: 1.1rem;
        font-weight: 500;
      }
    }
  }
}
// .boxWrapper.mainBox.bigBox {
//   .artBox-wrapper {
//     .artBox-desc {
//       color: black;
//       h3 {
//         color: black;
//       }
//       .artBox-desc-other {
//         a {
//           font-size: 1.1rem;
//         }
//       }
//     }
//   }
// }
// .boxWrapper.mainBox {
//   display: flex;
//   box-shadow: 0 0 13px 0 rgba(5, 30, 13, 0.1);
//   border-radius: 10px;
//   .artBox-wrapper {
//     flex-direction: column;
//     padding: 0;
//     width: 100%;
//     .artBox-img {
//       img {
//         width: 100%;
//         border-top-left-radius: 10px;
//         border-top-right-radius: 10px;
//         height: 220px;
//       }
//     }
//     .artBox-desc {
//       height: 110px;
//       width: 100%;
//       padding: 0.3rem;
//       h3 {
//         color: black;
//         line-height: 1.4rem;
//         font-size: 1.2rem;
//       }
//       .artBox-desc-other {
//         font-size: 1.1rem;
//         margin: 0;
//         margin-top: auto;
//         span {
//           font-size: 1rem;
//           display: inline;
//           display: none;
//         }
//       }
//     }
//   }
// }
// .boxWrapper.mainBox.box3.dark {
//   height: 340px;
//   margin: 0.8rem 0;
//   .artBox-wrapper {
//     .artBox-desc {
//       .artBox-desc-other {
//         a {
//           font-size: 1rem;
//         }
//         span {
//           font-size: 0.9rem;
//         }
//       }
//     }
//   }
// }
// .boxWrapper.mainBox.box3.white {
//   height: 340px;
// }
@media screen and (min-width: 768px) {
  .mainNews {
    min-width: 50%;
    height: calc(100% - 1.6rem);
    margin: 0.8rem 0.5rem 0.8rem 0.8rem;
    position: relative;
    border-radius: 5px;
    .artBox-wrapper {
      width: 100%;
      height: 100%;
      position: relative;
      .artBox-img {
        img {
          border-radius: 5px;
          height: 290px;
        }
      }
      .artBox-desc {
        border: none;
        width: 100%;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        margin: 0;
        padding: 0.5rem 0.6rem 0.3rem 0.6rem;
        position: absolute;
        background: linear-gradient(to bottom, rgba(143, 143, 143, 0) 0%, $silver5 10%, $silver5 15%);
        h3 {
          color: rgb(255, 255, 255);
          font-size: 1.6rem;
          line-height: 1.9rem;
          font-weight: 400;
          cursor: pointer;
          &:hover {
            color: rgb(231, 231, 231);
          }
        }
        .artBox-desc-other {
          a {
            font-size: 1.2rem;
            color: $green1;
            &:hover {
              text-decoration: underline;
            }
          }
          span {
            font-size: 1rem;
          }
        }
      }
    }
  }

  .mainNews.category {
    border-radius: 0;
    margin: 0;
    height: 500px;
    .artBox-wrapper {
      .artBox-img {
        img {
          border-radius: 0;
        }
      }
      .artBox-desc {
        border-radius: 0;
        width: 100%;
        padding: 2rem 0.6rem 0.5rem 0.6rem;
        background: linear-gradient(to bottom, rgba(143, 143, 143, 0) 0%, rgba(24, 24, 24, 0.5) 20%, rgba(24, 24, 24, 0.75) 90%);
        h3 {
          margin: 0;
          color: rgb(255, 255, 255);
          font-size: 1.9rem;
          line-height: 2.2rem;
          text-shadow: none;
          font-weight: 500;
          cursor: pointer;
        }
        .artBox-desc-other {
          a {
            padding: 0;
            font-size: 1.4rem;
            &:hover {
              text-decoration: none;
            }
          }
          span {
            font-size: 1.1rem;
          }
        }
      }
    }
  }
  .mainNews.category.news {
    height: 100%;
    // border: 1px solid red;
    .artBox-wrapper {
      height: 26rem;
      min-height: 100%;
      .artBox-img {
        img {
          height: 100%;
        }
      }
      .artBox-desc {
        padding: 2.2rem 0.6rem 0.2rem 0.6rem;
      }
    }
  }
  .boxWrapper {
    color: white;
    display: flex;
    flex-direction: column;
    .artBox-wrapper {
      padding: 0;
      .artBox-desc {
        h3 {
          font-size: 1.2rem;
          line-height: 1.4rem;
          margin: 0;
        }
        .artBox-desc-other {
          line-height: 1.2rem;
          a {
            line-height: 1.2rem;
            font-size: 1rem;
            color: $green1;
            margin-right: 0.2rem;
            &:hover {
              text-decoration: underline;
            }
          }
          span {
            font-size: 0.8rem;
          }
        }
      }
    }
  }
  .boxWrapper.mainBox {
    padding: 0;
    .artBox-wrapper {
      .artBox-desc {
        .artBox-desc-other {
          a {
            font-size: 1.1rem;
          }
        }
      }
    }
  }
  .boxWrapper.mainBox.box3.dark {
    height: 340px;
    background: $grey1;
    margin: 0;
    .artBox-wrapper {
      .artBox-img {
        height: 220px;
      }
      .artBox-desc {
        height: 120px;
        a {
          h3 {
            color: black;
          }
        }
        .artBox-desc-other {
          span {
            color: $grey3;
          }
        }
      }
    }
  }
  .boxWrapper.mainBox.box3.white {
    background: white;
    height: 340px;
    color: black;
    .artBox-wrapper {
      .artBox-img {
        height: 220px;
      }
    }
    .artBox-desc {
      h3 {
        padding: 0;
        color: black;
      }
      .artBox-desc-other {
        span {
          color: $grey3;
        }
      }
    }
  }
  .boxWrapper.mainBox.bigBox {
    height: 400px;
    margin: 0;
    .artBox-wrapper {
      height: 100%;
      .artBox-img {
        img {
          height: 280px;
          width: 100%;
        }
      }
      .artBox-desc {
        height: 120px;
        h3 {
          color: black;
          font-size: 1.35rem;
          line-height: 1.6rem;
        }
      }
    }
  }
  .boxWrapper.mainBox.new {
    height: 300px;
    .artBox-wrapper {
      .artBox-img {
        height: 180px;
        img {
          height: 100%;
        }
      }
    }
    .artBox-desc {
      height: 120px;
      h3 {
        line-height: 1.3rem;
      }
    }
  }
  .boxWrapper.sideBox {
    width: 100%;
    padding: 0.5rem 0;
    height: 500px;
    box-shadow: none;
    padding-right: 1rem; //???????????????
    .artBox-wrapper {
      align-items: center;
      .artBox-img {
        display: flex;
        height: 100%;
        width: 600px;
        justify-content: center;
        align-items: center;
        img {
          border-radius: 50%;
          box-shadow: 0 0 14px rgb(64, 64, 64);
          width: 380px;
          height: 380px;
          object-fit: cover;
          overflow: hidden;
        }
      }
      .artBox-desc {
        margin-top: auto;
        padding-bottom: 5rem;
        .artBox-desc-other {
          text-align: right;
        }
        a {
          color: $grey4;
          font-size: 1.8rem;
          font-weight: 700;
        }
        h3 {
          color: $green5;
          font-size: 2.8rem;
          line-height: 3.2rem;
          font-style: italic;
          font-weight: 600;
          text-align: right;
        }
        .artBox-lead {
          padding-top: 1rem;
          right: 0;
          padding-left: 4rem;
          text-align: right;
          font-size: 1.4rem;
          line-height: 1.8rem;
          color: $grey3;
        }

        .artBox-lead {
          display: inline;
          text-align: right;
        }
      }
    }
  }
  .boxWrapper.sideBox.right {
    .artBox-img {
      order: 2;
    }
    .artBox-desc {
      .artBox-desc-other,
      a,
      h3,
      .artBox-lead {
        text-align: left;
      }
      .artBox-lead {
        padding-left: 0;
      }
    }
  }
  .boxWrapper.sideBox.news {
    height: 140px;
    .artBox-wrapper {
      .artBox-desc {
        h3 {
          color: black;
          font-size: 1.1rem;
        }
      }
    }
  }
  .boxWrapper.box1 {
    height: 138px;
    padding: 0.8rem 0;
    border: none;
    border-bottom: 1px solid $grey2;
    .artBox-wrapper {
      .artBox-img {
        img {
          width: 150px;
        }
      }
      .artBox-desc {
        h3 {
          color: white;
          font-size: 1.2rem;
        }
      }
    }
  }
  .boxWrapper.box1:last-child {
    border: none;
  }
  .boxWrapper.box2 {
    padding: 0.8rem 0;
    height: 210px;
    width: 100%;
    .artBox-wrapper {
      width: 100%;
      height: 100%;
      .artBox-img {
        img {
          padding: 0;
          // DUBLE CHECK!!!!!!!!!!!!!!!!!!!!!!!!!!!!
          width: 240px;
          height: 100%; //????
          border-radius: 8px;
          object-fit: cover;
          overflow: hidden;
        }
      }
      .artBox-desc {
        width: 100%;
        h3 {
          font-size: 1.4rem;
          line-height: 1.8rem;
        }
        .artBox-desc-other {
          margin-top: auto;
          color: $green1;
          letter-spacing: 0.05rem;
          font-weight: bold;
          font-size: 1.2rem;
          a {
            font-size: 1.2rem;
          }
          span {
            font-size: 1rem;
          }
        }
      }
    }
  }
  .boxWrapper.topNews {
    height: 100%;
    .artBox-wrapper {
      flex-direction: row;
      position: relative;
      .artBox-img {
        height: 100%;
        // border: 3p solid blue;
        padding: 0;
        width: calc(100% - 420px);
        // clip-path: polygon(0 71%, 26% 90%, 61% 90%, 100% 75%, 100% 0, 0% 0%);
        img {
          // position: sticky;
          // z-index: -1;
        }
      }
      .artBox-desc {
        height: 100%;
        position: absolute;
        width: 420px;
        // width: 120px;
        // margin: 0.6rem;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        background: rgba(0, 0, 0, 0.357);
        a {
          h3 {
            // margin-top: 30%;
            padding-top: 15%;
            // border: 1px solid red;
            margin: 0;
            // margin-top: auto;
            color: white;
            font-size: 2.8rem;
            line-height: 3.2rem;
            width: 50%;
            text-align: center;
            transform: translate(50%);
            // padding-left: 25%;
          }
        }
        .artBox-desc-other {
          display: none;
        }
        .artBox-lead {
          opacity: 0.8;
          font-size: 1.4rem;
          font-style: italic;
          line-height: 1.6rem;
          margin-top: 0.5rem;
          color: white;
          width: 50%;
          text-align: center;
          transform: translate(50%);
        }
        button {
          display: none;
          font-size: 1.2rem;
          padding: 0.3rem;
        }
      }
    }
  }
  .boxWrapper.box3 {
    background: $dark4;
    height: 300px;
    padding: 0;
    border: none;
    border-radius: 5px;
    .artBox-wrapper {
      flex-direction: column;
      .artBox-img {
        height: 180px;
        img {
          border-radius: 0;
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
          width: 100%;
        }
      }
      .artBox-desc {
        padding: 0.3rem 0.4rem;
        width: 100%;
        height: 120px;
        h3 {
          color: rgb(243, 243, 243);
          line-height: 1.5rem;
          font-size: 1.25rem;
        }
        .artBox-desc-other {
          a {
            font-size: 1.1rem;
          }
          span {
            font-size: 1rem;
          }
        }
      }
    }
  }
  .boxWrapper.box4 {
    box-shadow: 0 0 13px 0 rgba(5, 30, 13, 0.1);
    border: none;
    border-radius: 10px;
    height: 140px;
    padding: 0;
    .artBox-wrapper {
      .artBox-img {
        img {
          width: 200px;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
        }
      }
      .artBox-desc {
        padding: 0.2rem 0.4rem;
        h3 {
          color: black;
          font-size: 1.2rem;
        }
      }
    }
  }
}
