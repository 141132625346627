@import "../../index.scss";

.redakcija {
  display: flex;
  flex-direction: column;
  span {
    font-weight: bold;
    text-transform: uppercase;
  }
  input {
    width: 100%;
    height: 100%;
  }
}
