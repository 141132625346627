@import "../../../index.scss";
.toggle__button {
  position: absolute;
  display: flex;
  top: 0;
  right: 0;
  height: 50px;
  width: 3.4rem;
  justify-content: center;
  align-items: center;
  z-index: +1;
}
.toggle {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // height: 1.8rem;
  height: 26px;
  background: transparent;
  border: none;
  cursor: pointer;
  // z-index: +100;
  &:focus {
    outline: none;
  }
  .toggle-button-line {
    width: 2rem;
    height: 3px;
    background: white;
    transition: all 0.1s linear;
    border-radius: 3px;
  }
}
@media screen and (min-width:768px) {
  .toggle__button {
    display: none;
  }
}
