@import "../../index.scss";
.gallery {
  display: flex;
  flex-direction: column;
  h3 {
    font-style: italic;
    width: auto;
    // display: inline;
    // height: 500px;
    text-align: center;
    font-size: 2rem;
    color: $green4;
    // border-bottom: 5px solid $green3;
  }
  .gallery-wrapper {
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(7, 1fr);
    gap: 0.35rem;

    .img1 {
      grid-area: 1 / 1 / 4 / 3;
    }
    .img2 {
      grid-area: 4 / 1 / 6 / 2;
    }
    .img3 {
      grid-area: 4 / 2 / 6 / 3;
    }
    .img4 {
      grid-area: 6 / 1 / 8 / 2;
    }
    .img5 {
      grid-area: 6 / 2 / 8 / 3;
    }
    .img1,
    .img2,
    .img3,
    .img4,
    .img5 {
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 1rem;
      }
    }
  }
}

@media screen and (min-width:$medium) {
  .gallery {
    h3 {
      // font-style: italic;
      // width: auto;
      // display: inline;
      // height: 500px;
      // text-align: center;
      font-size: 3rem;
      // color: $green4;
      // border-bottom: 5px solid $green3;
    }
    .gallery-wrapper {
      // margin: 0 auto;
      height: 100%;
      // display: grid;
      grid-template-columns: repeat(7, 1fr);
      grid-template-rows: repeat(2, 1fr);
      gap: 0.85rem;
      .img1 {
        grid-area: 1 / 1 / 3 / 4;
      }
      .img2 {
        grid-area: 1 / 4 / 2 / 6;
      }
      .img3 {
        grid-area: 1 / 6 / 2 / 8;
      }
      .img4 {
        grid-area: 2 / 4 / 3 / 6;
      }
      .img5 {
        grid-area: 2 / 6 / 3 / 8;
      }
      // .img1,
      // .img2,
      // .img3,
      // .img4,
      // .img5 {
      //   width: 100%;
      //   height: 100%;
      //   img {
      //     width: 100%;
      //     height: 100%;
      //     object-fit: cover;
      //     border-radius: 1rem;
      //   }
      // }
    }
  }
}
